import React from 'react'

const InfoModal = ({
  show = false,
  isSuccess = false,
  userName = '',
  modalMessage = ''
}) => {
  if (show) {
    return (
      <div id="showInfoModal">
         {isSuccess
          ? (
            <div className='alert custom-alert-success'>
              {userName} {modalMessage}
            </div>
         )
         : (
            <div className='alert alert-danger'>
              {modalMessage}
            </div>
         )}
      </div>
    )
  }
  return null
}

export default InfoModal
