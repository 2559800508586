import React from 'react'
import PageMetaTags from '../../../../components/PageMetaTags'

const DataManagerPrivacyAndTerms = () => (
  <main className='l-main-content' id='home'>
    <PageMetaTags
      title="Data Manager - Privacy Policy"
      description="Privacy Policy of Data Manager."
      ogTitle="Data Manager - Privacy Policy"
      ogDescription="Privacy Policy of Data Manager."
      ogType="article"
      ogUrl="https://raccoon.pet/shopline/apps/datamanager/privacy-and-terms"
      />
    <section className='section-first section-first_type-2'>
      <div className='container'>
        <h4>Data Manager Privacy Policy</h4>
        <p><strong>Effective Date:</strong> 11.07.2024</p>

        <p>Data Manager ("we," "us," or "our") is committed to protecting the privacy of our users ("you" or "your"). This Privacy Policy explains what information we collect, how we use it, and your rights regarding your data.</p>

        <h5>Information We Collect</h5>
        <p>We collect the following information in order to provide and improve our service:</p>

        <h6>User Information:</h6>
        <ul>
            <li><strong>Store Domain:</strong> We store your Shopline store domain to identify your account and establish a secure connection with Shopline's API.</li>
            <li><strong>Access Token:</strong> We securely store an access token to your Shopline store's admin API to facilitate data import and export functionalities. This token is used only for authorized calls to Shopline's API and is not used for any other purpose.</li>
        </ul>

        <h6>Import Information (upon user request for deletion):</h6>
        <ul>
            <li><strong>Import Details:</strong> We track information about your import activities, including the type of data imported (e.g., products, customers, metafields), the timestamp of the import, and the results (success or failure, including error messages). This data may contain sensitive information depending on the data you import. We retain this data until the application is uninstalled.</li>
        </ul>

        <h6>Uploaded Files:</h6>
        <ul>
            <li><strong>CSV Files:</strong> We can temporarily store uploaded CSV files for a period of one day to facilitate the import process.</li>
        </ul>

        <h6>Error Logs:</h6>
        <ul>
            <li><strong>Import Error Logs:</strong> We maintain logs specifically for import errors. These logs are retained for one month for your troubleshooting purposes.</li>
        </ul>

        <h5>How We Use Your Information</h5>
        <p>We use the information we collect for the following purposes:</p>
        <ul>
            <li>To provide and improve Data Manager services, including data import and export functionalities.</li>
            <li>To authenticate your access and establish a secure connection with Shopline's API.</li>
            <li>To track your import history and provide results upon your request (default behavior is to delete after one month). Import details may contain sensitive information depending on the data you import.</li>
            <li>To analyze and understand usage trends related to import errors to improve our application.</li>
            <li>To comply with legal and regulatory requirements.</li>
        </ul>

        <h5>Data Retention</h5>
        <p>We retain your information for the following periods:</p>
        <ul>
            <li><strong>Access Token:</strong> Stored securely for the duration of your active use of Data Manager.</li>
            <li><strong>Import Details:</strong> Retained until uninstallation of the application.</li>
            <li><strong>Uploaded CSV Files:</strong> Temporarily stored for one day.</li>
            <li><strong>Error Logs:</strong> Retained for one month.</li>
        </ul>

        <h5>Your Rights</h5>
        <p>You have certain rights regarding your data under GDPR regulations. These rights include:</p>
        <ul>
            <li>The right to access your personal information (including import details).</li>
            <li>The right to rectify inaccurate information.</li>
            <li>The right to request the erasure of your personal information, including import details.</li>
            <li>The right to restrict the processing of your personal information.</li>
            <li>The right to data portability.</li>
        </ul>

        <h5>GDPR Webhooks</h5>
        <p>Data Manager implements GDPR webhooks that allow you to request the deletion of all information pertaining to your store and customer data associated with your Data Manager account. This includes import details collected during your use of the application.</p>

        <h5>How to Exercise Your Rights</h5>
        <p>If you wish to exercise any of these rights, please contact us at <a href="mailto:pavel@raccoon.pet">pavel@raccoon.pet</a>.</p>

        <h5>Security</h5>
        <p>We take appropriate security measures to protect your information against unauthorized access, disclosure, alteration, or destruction. However, no internet transmission or electronic storage method can be guaranteed to be 100% secure.</p>

        <h5>Changes to this Privacy Policy</h5>
        <p>We may update this Privacy Policy from time to time and will notify you of any changes by posting the new policy on our website. Your continued use of our services after any such modifications will constitute your acknowledgment of the modified Privacy Policy and agreement to abide and be bound by the modified policy. We believe that transparency is key when it comes to privacy, which is why we will always keep you informed of any changes we make to our policies.</p>

        <h5>Contact Us</h5>
        <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:pavel@raccoon.pet">pavel@raccoon.pet</a>.</p>
      </div>
    </section>
  </main>
)

export default DataManagerPrivacyAndTerms
