import React from 'react';
import MetaTags from 'react-meta-tags';

class PageMetaTags extends React.Component {
  render() {
    return (
      <MetaTags>
        <title>{this.props.title}</title>
        <meta property="og:title" content={this.props.ogTitle} />
        <meta property="og:description" content={this.props.ogDescription} />
        <meta property="og:url" content={this.props.ogUrl} />
        <meta property="og:type" content={this.props.ogType} />
        <meta name="description" content={this.props.description} />
      </MetaTags>
    )
  }
}

export default PageMetaTags