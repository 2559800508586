import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import Home from './pages/home'
import Footer from './components/Footer'
// import SearchModal from './components/SearchModal'
import MobileMenu from './components/MobileMenu'
import Header from './components/Header'
// import Loader from './components/Loader'
import CookiePolicy from './pages/cookie-policy'
import PrivacyPolicy from './pages/privacy-policy'
import DataManagerPrivacyAndTerms from './pages/shopline/apps/datamanager/privacy-and-terms'
import DataManagerFAQ from './pages/shopline/apps/datamanager/faq'

const url = `${process.env.REACT_APP_BACKEND_HOST}/api/v1/displayInfo`

const App = () => {
  const [doShowProjects, setDoShowProjects] = useState(false)
  const [doShowPartners, setDoShowPartners] = useState(false)
  const [doShowBlog, setDoShowBlog] = useState(false)

  useEffect(() => {
    (async () => {
      try {
        const res = await fetch(url)

        const data = await res.json()

        setDoShowPartners(data.doShowPartners)
        setDoShowProjects(data.doShowProjects)
        setDoShowBlog(data.doShowBlog)
      } catch (err) {
        console.log(err)
      }
    })()
  }, [])

  return (
    <Router>
      {/* <Loader /> */}
      <div
        className='l-theme animated-css'
        data-header='sticky'
        data-header-top={200}
        data-canvas='container'
      >
        <Switch>
          <Route exact path='/'>
            <MobileMenu
              doShowProjects={doShowProjects}
              doShowBlog={doShowBlog}
              isHomepage={true}
            />
            <Header
              doShowProjects={doShowProjects}
              doShowBlog={doShowBlog}
              isHomepage={true}
            />
            <Home
              doShowProjects={doShowProjects}
              doShowPartners={doShowPartners}
              doShowBlog={doShowBlog}
            />
            <Footer
              isHomepage={true}
              doShowBlog={doShowBlog}
              doShowProjects={doShowProjects}
            />
          </Route>
          <Route exact path='/cookie-policy'>
            <MobileMenu
              doShowProjects={doShowProjects}
              doShowBlog={doShowBlog}
              isHomepage={false}
            />
            <Header
              fixed
              doShowProjects={doShowProjects}
              doShowBlog={doShowBlog}
              isHomepage={false}
            />
            <CookiePolicy />
            <Footer
              isHomepage={false}
              doShowBlog={doShowBlog}
              doShowProjects={doShowProjects}
            />
          </Route >
          <Route exact path='/privacy-policy'>
            <MobileMenu
              doShowProjects={doShowProjects}
              doShowBlog={doShowBlog}
              isHomepage={false}
            />
            <Header
              fixed
              doShowProjects={doShowProjects}
              doShowBlog={doShowBlog}
              isHomepage={false}
            />
            <PrivacyPolicy />
            <Footer
              isHomepage={false}
              doShowBlog={doShowBlog}
              doShowProjects={doShowProjects}
            />
          </Route >
          <Route exact path='/shopline/apps/datamanager/faq'>
            <MobileMenu
              doShowProjects={doShowProjects}
              doShowBlog={doShowBlog}
              isHomepage={false}
            />
            <Header
              fixed
              doShowProjects={doShowProjects}
              doShowBlog={doShowBlog}
              isHomepage={false}
            />
            <DataManagerFAQ />
            <Footer
              isHomepage={false}
              doShowBlog={doShowBlog}
              doShowProjects={doShowProjects}
            />
          </Route >
          <Route exact path='/shopline/apps/datamanager/privacy-and-terms'>
            <MobileMenu
              doShowProjects={doShowProjects}
              doShowBlog={doShowBlog}
              isHomepage={false}
            />
            <Header
              fixed
              doShowProjects={doShowProjects}
              doShowBlog={doShowBlog}
              isHomepage={false}
            />
            <DataManagerPrivacyAndTerms />
            <Footer
              isHomepage={false}
              doShowBlog={doShowBlog}
              doShowProjects={doShowProjects}
            />
          </Route >
          <Route path='*' render={() => <Redirect to ='/' />} />
        </Switch>
      </div>
    </Router>
  )
}

export default App
