import React from 'react'
// import AnchorLink from 'react-anchor-link-smooth-scroll'
import LinkWithAnchor from './LinkWithAnchor'
import MenuToggle from './MenuToggle'

const MobileMenu = ({
  fixed = false,
  doShowProjects = false,
  isHomepage = false,
  doShowBlog = false
}) => (
  <div id='fixedMenu' className={`${fixed ? 'wrap-fixed-menu static-page' : 'wrap-fixed-menu'}`}>
    <nav className='fullscreen-center-menu'>
      <div className='menu-main-menu-container'>
        <ul className='nav navbar-nav'>
          <li>
            <LinkWithAnchor
              to='home'
              isHomepage={isHomepage}
              text='Home'
            />
          </li>
          <li>
            <LinkWithAnchor
              to='capabilities'
              isHomepage={isHomepage}
              text='Capabilities'
            />
          </li>
          {doShowProjects && (
            <li>
              <LinkWithAnchor
                to='works'
                isHomepage={isHomepage}
                text='Works'
              />
            </li>
          )}
          <li>
            <LinkWithAnchor
              to='services'
              isHomepage={isHomepage}
              text='Services'
            />
          </li>
          <li>
            <LinkWithAnchor
              to='why-us'
              isHomepage={isHomepage}
              text='Why Us'
            />
          </li>
          {doShowBlog && (
            <li>
              <LinkWithAnchor
                to='blog'
                isHomepage={isHomepage}
                text='Blog'
              />
            </li>
          )}
          <li>
            <LinkWithAnchor
              to='contact'
              isHomepage={isHomepage}
              text='Contact'
            />
          </li>
        </ul>
      </div>
    </nav>
    <MenuToggle />
  </div>
)

export default MobileMenu
