import React from 'react'
// import { Link, NavLink } from 'react-router-dom'
import LinkWithAnchor from './LinkWithAnchor'
import Logo from './Logo'
import MenuToggle from './MenuToggle'

const Header = ({
  fixed = false,
  doShowProjects = false,
  isHomepage = false,
  doShowBlog = false
}) => (
  <>
    <header className={`${fixed ? ' no-sc header header_mod-a header-topbar-hidden header-boxed-width navbar-fixed-top header-background-trans header-color-white header-logo-white header-navibox-1-left header-navibox-2-right navbar-scrolling' : 'header header_mod-a header-topbar-hidden header-boxed-width navbar-fixed-top header-background-trans header-color-white header-logo-white header-navibox-1-left header-navibox-2-right'}`}>
      <div className='container container-boxed-width'>
        {/* Main menu section */}
        <nav className='navbar' id='nav'>
          <div className='header-navibox-1'>
            <Logo />
            <MenuToggle />
          </div>
          <div className='header-navibox-2'>
            <ul className='main-menu nav navbar-nav'>
              <li>
                <LinkWithAnchor
                  to='home'
                  isHomepage={isHomepage}
                  text='Home'
                />
              </li>
              <li>
                <LinkWithAnchor
                  to='capabilities'
                  isHomepage={isHomepage}
                  text='Capabilities'
                />
              </li>
              {doShowProjects && (
                <li>
                  <LinkWithAnchor
                    to='works'
                    isHomepage={isHomepage}
                    text='Works'
                  />
                </li>
              )}
              <li>
                <LinkWithAnchor
                  to='services'
                  isHomepage={isHomepage}
                  text='Services'
                />
              </li>
              <li>
                <LinkWithAnchor
                  to='why-us'
                  isHomepage={isHomepage}
                  text='Why Us'
                />
              </li>
              {doShowBlog && (
                <li>
                  <LinkWithAnchor
                    to='blog'
                    isHomepage={isHomepage}
                    text='Blog'
                  />
                </li>
              )}
              <li>
                <LinkWithAnchor
                  to='contact'
                  isHomepage={isHomepage}
                  text='Contact'
                />
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  </>
)

export default Header
