/* eslint-disable react/prop-types */
import React from 'react'
import spImage from '../assets/media/images/gallery/First.jpg'
import spImage2 from '../assets/media/images/gallery/Second.jpg'
import spImage3 from '../assets/media/images/gallery/Third.jpg'

// import spImage from '../assets/media/images/gallery/1.jpg'
import spImageSmall from '../assets/media/images/gallery/1-small.jpg'
import spImageXsSmall from '../assets/media/images/gallery/1-xs-small.jpg'
// import spImage2 from '../assets/media/images/gallery/5.jpg'
import spImage2Small from '../assets/media/images/gallery/5-small.jpg'
import spImage2XsSmall from '../assets/media/images/gallery/5-xs-small.jpg'
// import spImage3 from '../assets/media/images/gallery/8.jpg'
import spImage3Small from '../assets/media/images/gallery/8-small.jpg'
import spImage3XsSmall from '../assets/media/images/gallery/8-xs-small.jpg'
import mac1 from '../assets/media/images/mac/mac-4.png'
import mac2 from '../assets/media/images/mac/mac-3.png'
import mac3 from '../assets/media/images/mac/mac-5.png'
import portfolioDetails1 from '../assets/media/components/b-isotope/430x260_1.jpg'
import portfolioDetails2 from '../assets/media/components/b-isotope/435x550_1.jpg'
import portfolioDetails3 from '../assets/media/components/b-isotope/430x260_2.jpg'
import portfolioDetails4 from '../assets/media/components/b-isotope/435x550_2.jpg'
import portfolioDetails5 from '../assets/media/components/b-isotope/430x260_3.jpg'
import portfolioDetails6 from '../assets/media/components/b-isotope/430x260_4.jpg'
import imgResponsive2 from '../assets/media/content/other/880x814_1.jpg'
import imageZoom from '../assets/media/content/posts/535x450/1.jpg'
import imageZoom1 from '../assets/media/content/posts/263x250/1.jpg'
import imageZoom2 from '../assets/media/content/posts/263x250/2.jpg'
import carousel1 from '../assets/media/components/b-brands/logo-1_light.png'
import carousel2 from '../assets/media/components/b-brands/logo-2_light.png'
import carousel3 from '../assets/media/components/b-brands/logo-3_light.png'
import carousel4 from '../assets/media/components/b-brands/logo-4_light.png'
import carousel5 from '../assets/media/components/b-brands/logo-5_light.png'
import sectionFormImg from '../assets/media/content/other/880x742_1.jpg'
import ContactForm from '../components/ContactForm'
import content from '../assets/content.json'
import ServicesSection from '../components/ServicesSection'
import AnchorLink from 'react-anchor-link-smooth-scroll'
// import CookieConsent, { Cookies } from 'react-cookie-consent'
import CookieConsent from 'react-cookie-consent'

import CustomerSupportIcon from '../assets/icons/customer.svg'
import UxIcon from '../assets/icons/ux.svg'
import ConfigIcon from '../assets/icons/configuration.svg'
import SolutionIcon from '../assets/icons/solution.svg'
import CartIcon from '../assets/icons/cart.svg'
import PerformanceIcon from '../assets/icons/performance.svg'
import AnalyticsIcon from '../assets/icons/analytics.svg'
import EcommerceStrategyIcon from '../assets/icons/ecommerce-strategy.svg'

import ecommerceInsideIcon from '../assets/icons/ecommerce-io.svg'
import resultIcon from '../assets/icons/result.svg'
import solversIcon from '../assets/icons/solvers.svg'

import FrontIcon from '../assets/icons/source.svg'
import BackIcon from '../assets/icons/back.svg'
import StarIcon from '../assets/icons/star.svg'
import ConsultingIcon from '../assets/icons/consulting.svg'
// import salesforceLogo from '../assets/media/content/other/salesforce-2.svg'
import salesforceLogoWhite from '../assets/media/content/other/salesforceWhite-2.svg'
import salesforceLogoBlue from '../assets/media/content/other/salesforceBlue-2.svg'
// import bigCommerceLogo from '../assets/media/content/other/bigcommerce-2.svg'

const Home = ({
  doShowPartners = false,
  doShowProjects = false,
  doShowBlog = false
}) => {
  return (
    <>
      <main className='l-main-content' id='home'>
        <div className='main-slider main-slider_lg slider-pro' id='main-slider' data-slider-width='100%' data-slider-height='950px' data-slider-arrows='true' data-slider-buttons='true'>
          <div className='sp-slides'>
            {/* Slide 1 */}
            <div className='sp-slide'>
              <img className='sp-image'
                  src={spImage}
                  data-small={spImageXsSmall}
                  data-medium={spImageSmall}
                  alt='Slide 1'
              />

              <div className='container'>
                <div className='row'>
                  <div className='col-sm-10 col-sm-offset-1'>
                    {/* <div className='main-slider__info sp-layer' data-width='100%' data-show-transition='left' data-hide-transition='left' data-show-duration={2000} data-show-delay={1200} data-hide-delay={400}>Welcome to Raccoon LLC</div> */}
                    <div className='main-slider__info'></div>
                    <h2 className='main-slider__title sp-layer' data-width='100%' data-show-transition='left' data-hide-transition='left' data-show-duration={800} data-show-delay={400} data-hide-delay={400}>We know how to stay on the top of ecommerce trends in a <span className='main-slider__title-emphasis'>dynamic</span> environment<br /></h2>
                  </div>
                </div>
              </div>
            </div>
            {/* Slide 2 */}
            <div className='sp-slide'>
              <img className='sp-image'
                  src={spImage2}
                  data-small={spImage2XsSmall}
                  data-medium={spImage2Small}
                  alt='Slide 2'
              />
              <div className='container'>
                <div className='row'>
                  <div className='col-sm-10 col-sm-offset-1'>
                    {/* <div className='main-slider__info sp-layer' data-width='100%' data-show-transition='left' data-hide-transition='left' data-show-duration={2000} data-show-delay={1200} data-hide-delay={400}>Raccoon LLC - Creative Minds</div> */}
                    <div className='main-slider__info'></div>
                    <h2 className='main-slider__title sp-layer' data-width='100%' data-show-transition='left' data-hide-transition='left' data-show-duration={800} data-show-delay={400} data-hide-delay={400}>the road that Leads to<span className='main-slider__title-emphasis'>Successful Business</span></h2>
                  </div>
                </div>
              </div>
            </div>
            {/* Slide 3 */}
            <div className='sp-slide'>
              <img className='sp-image'
                   src={spImage3}
                   data-small={spImage3XsSmall}
                   data-medium={spImage3Small}
                   alt='Slide 3'
              />
              <div className='container'>
                <div className='row'>
                  <div className='col-sm-10 col-sm-offset-1'>
                    {/* <div className='main-slider__info sp-layer' data-width='100%' data-show-transition='left' data-hide-transition='left' data-show-duration={2000} data-show-delay={1200} data-hide-delay={400}>Raccoon LLC - Professional Teams</div> */}
                    <div className='main-slider__info'></div>
                    <h2 className='main-slider__title sp-layer' data-width='100%' data-show-transition='left' data-hide-transition='left' data-show-duration={800} data-show-delay={400} data-hide-delay={400}>Let&apos;s Create Something Great<br /><span className='main-slider__title-emphasis'>Together</span></h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end .main-slider */}

        <section className='b-info'>
          <div className='b-info__inner border-l_prim border-r_prim'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-8'>
                  <div className='b-info__title b-info__title--first-section'>
                    We are Salesforce Partners
                  </div>
                  <div className='b-info__text'>
                    Salesforce provides a comprehensive and integrated set of cloud applications that can be combined to acquire new business, manage client relationships, communicate in a highly targeted way, support customers, and sell to consumers and businesses. Extensive use of data produced throughout the cycle of interactions enables data-driven and AI-powered decision-making.
                  </div>
                </div>
                <div className='col-md-4 b-info__logo-big-container'>
                  <a href='https://www.salesforce.com/' target='_blank' rel='noreferrer'>
                    <img className='b-info__logo-big' src={salesforceLogoBlue} alt='salesforce' />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* end .b-info */}
        <div className='section-maincontent'>
          <section className='section-first section-first_type-2'>
            <div className='container'>
              <div className='row'>
                <div className='col-xs-12'>
                  <div className='ui-wrap-title-block'>
                    <div className='ui-wrap-title-center'>
                      <div className='ui-subtitle-block ui-subtitle-block--center'>About our partnership</div>
                    </div>
                  </div>
                  <p className='ui-description'>
                    Raccoon LLC is a digital consulting agency and e-commerce service provider with capabilities that span consulting, solution providing, creative, systems integrator and technology services. We combine proven methodologies, deep technical expertise to create digital commerce experiences that engage and convert consumers and buyers across channels and devices. We work with best-of-breed technologies on Salesforce Platform and provide our clients with innovative solutions that address complex business problems. We offer holistic Salesforce product, implementation expertise using B2C Commerce Cloud, Service Cloud and Salesforce OMS.
                  </p>
                  <p className='ui-description'>
                    We are delivering our projects with passion to new technologies and as one team with our Clients, and we know how can we achieve high end solution, which will meet our customers’ requirements.
                  </p>
                </div>
              </div>
            </div>
          </section>
          {/* end .section-first */}

          {/* <section className='section-first section-first_type-2'>
            <div className='container'>
              <div className='row'>
                <div className='col-xs-12'>
                  <div className='section-first__header'>
                    <h2 className='ui-title-inner-2 pacifica'>
                      We are <img className='section-first__logo' src={salesforceLogo} alt='salesforce' /><span className='section-first__text'>Salesforce</span> Commerce Cloud and <img className='section-first__logo bigcommerce-logo' src={bigCommerceLogo} alt='BigCommerce' /><span className='section-first__text'>BigCommerce</span> Partners
                    </h2>
                    <p className='section-first__description'>Raccoon LLC is a digital consulting agency and e-commerce service provider with capabilities that span consulting, solution providing, creative, systems integrator and technology services. We combine proven methodologies, deep technical expertise to create digital commerce experiences that engage and convert consumers and buyers across channels and devices. We work with best-of-breed technologies on Salesforce Platform and provide our clients with innovative solutions that address complex business problems. We offer holistic Salesforce product, implementation expertise using Commerce Cloud.</p>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
        {/* end .section-first */}
        <section className='section-features' id='capabilities'>
          <div className='container'>
            <div className='row'>
              <div className='col-xs-12'>
                <div className='ui-wrap-title-block'>
                  <div className='ui-wrap-title-center'>
                    <h2 className='ui-title-block' id='revealfx1'>Here are our</h2>
                    <div className='ui-subtitle-block'>Capabilities</div>
                  </div>
                  <p className='ui-description'>Open new horizons of digital commerce capabilities together with us</p>
                </div>
              </div>
            </div>
            <div className='row capabilities-tiles'>
              <div className='col-sm-6 col-md-4'>
                <section className='b-advantages b-advantages-2'>
                  <img className='capb-icon' src={CustomerSupportIcon} alt=''/>
                  <h3 className='b-advantages__title ui-title-inner'>Consulting</h3>
                  <div className='b-advantages__info'>
                - Business processes review<br />
                - Implementation of Salesforce Best Practices<br />
                - Configuration of full-scale solutions <br />
                  </div>
                </section>
                {/* end .b-advantages */}
              </div>
              <div className='col-sm-6 col-md-4'>
                <section className='b-advantages b-advantages-2'>
                  <img className='capb-icon' src={UxIcon} alt=''/>
                  <h3 className='b-advantages__title ui-title-inner'>{content.en.capabilities.design.title}</h3>
                  <p className='b-advantages__info'>{content.en.capabilities.design.description}</p>
                </section>
                {/* end .b-advantages */}
              </div>
              <div className='col-sm-6 col-md-4'>
                <section className='b-advantages b-advantages-2'>
                  <img className='capb-icon' src={ConfigIcon} alt=''/>
                  <h3 className='b-advantages__title ui-title-inner'>Support and Maintenance</h3>
                  <div className='b-advantages__info'>
                - Different levels of user's support<br />
                - Testing and bug fixing<br />
                - Release management<br />
                - Solution improvement<br />
                  </div>
                </section>
                {/* end .b-advantages */}
              </div>
              <div className='col-sm-6 col-md-4'>
                <section className='b-advantages b-advantages-2'>
                  <img className='capb-icon' src={SolutionIcon} alt=''/>
                  <h3 className='b-advantages__title ui-title-inner'>Solutions Not Just Services</h3>
                  <p className='b-advantages__info'>We are focused on designing, architecting and delivering solutions rather than just services</p>
                </section>
                {/* end .b-advantages */}
              </div>
              <div className='col-sm-6 col-md-4'>
                <section className='b-advantages b-advantages-2'>
                  <img className='capb-icon' src={CartIcon} alt=''/>
                  <h3 className='b-advantages__title ui-title-inner'>Launch ecommerce sites</h3>
                  <p className='b-advantages__info'>We are capable of launching ecommerce giants sites from scratch. Our teams are highly experienced in it </p>
                </section>
                {/* end .b-advantages */}
              </div>
              <div className='col-sm-6 col-md-4'>
                <section className='b-advantages b-advantages-2'>
                  <img className='capb-icon' src={PerformanceIcon} alt=''/>
                  <h3 className='b-advantages__title ui-title-inner'>Site Performance</h3>
                  <p className='b-advantages__info'>{content.en.capabilities.improve.description}</p>
                </section>
                {/* end .b-advantages */}
              </div>
            </div>
            <div className='row'>
              <div className='col-xs-12'>
                <div className="laptop-wrapp">
                  <div className='scrollme-wrap'>
                    <div className='scrollme scroll-mac2'>
                      <div className='animateme' data-when='enter' data-from='0.5' data-to={0} data-opacity={1} data-translatex={-200} data-rotatez={0}>
                        <img className='section-features__bg img-responsive' src={mac1} alt='foto' />
                      </div>
                    </div>
                    <div className='scrollme scroll-mac1'>
                      <div className='animateme' data-when='enter' data-from='0.5' data-to={0} data-opacity={1} data-scalex='1.3'>
                        <img className='section-features__bg img-responsive' src={mac2} alt='foto' />
                      </div>
                    </div>
                    <div className='scrollme scroll-mac3'>
                      <div className='animateme' data-when='enter' data-from='0.5' data-to={0} data-opacity={1} data-translatex={200} data-rotatez={0}>
                        <img className='section-features__bg img-responsive' src={mac3} alt='foto' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* end .section-features */}
        {doShowProjects
          ? (
            <section className='section-isotope b-isotope' id='works'>
              <div className='container'>
                <div className='row'>
                  <div className='col-lg-6 col-md-5'>
                    <h2 className='ui-title-block' id='revealfx2'>Latest Works !</h2>
                    <div className='ui-subtitle-block'>What
                      <br /><span className='shuffle'>We did</span></div>
                  </div>
                  <div className='col-lg-6 col-md-7'>
                    <ul className='b-isotope-filter b-isotope-filter_mrg-btm_lg list-inline'>
                      <li className='current'><a href='home-2.html' data-filter='*'>all works</a><span className='b-isotope-filter__title'>All Works</span></li>
                      <li><a href='home-2.html' data-filter='.design'>web design</a><span className='b-isotope-filter__title'>Web Design</span></li>
                      <li><a href='home-2.html' data-filter='.photography'>photography</a><span className='b-isotope-filter__title'>Photography</span></li>
                      <li><a href='home-2.html' data-filter='.video'>video</a><span className='b-isotope-filter__title'>Video</span></li>
                      <li><a href='home-2.html' data-filter='.illustration'>illustration</a><span className='b-isotope-filter__title'>Illustration</span></li>
                    </ul>
                  </div>
                </div>
              </div>
              <ul className='b-isotope-grid grid list-unstyled js-zoom-gallery'>
                <li className='grid-sizer' />
                <li className='b-isotope-grid__item grid-item design illustration'>
                  <a className='b-isotope-grid__inner' href='portfolio-details.html'><img src={portfolioDetails1} alt='foto' /><span className='b-isotope-grid__wrap-info hvr-shutter-in-vertical'><span className='b-isotope-grid__info'><span className='b-isotope-grid__title'>emma card</span><span className='b-isotope-grid__categorie'>Branding</span></span>
                  </span>
                  </a>
                </li>
                <li className='b-isotope-grid__item grid-item design'>
                  <a className='b-isotope-grid__inner' href='portfolio-details.html'><img src={portfolioDetails2} alt='foto' /><span className='b-isotope-grid__wrap-info hvr-shutter-in-vertical'><span className='b-isotope-grid__info'><span className='b-isotope-grid__title'>emma card</span><span className='b-isotope-grid__categorie'>Branding</span></span>
                  </span>
                  </a>
                </li>
                <li className='b-isotope-grid__item grid-item photography video illustration'>
                  <a className='b-isotope-grid__inner' href='portfolio-details.html'><img src={portfolioDetails3} alt='foto' /><span className='b-isotope-grid__wrap-info hvr-shutter-in-vertical'><span className='b-isotope-grid__info'><span className='b-isotope-grid__title'>emma card</span><span className='b-isotope-grid__categorie'>Branding</span></span>
                  </span>
                  </a>
                </li>
                <li className='b-isotope-grid__item grid-item design'>
                  <a className='b-isotope-grid__inner' href='portfolio-details.html'><img src={portfolioDetails4} alt='foto' /><span className='b-isotope-grid__wrap-info hvr-shutter-in-vertical'><span className='b-isotope-grid__info'><span className='b-isotope-grid__title'>emma card</span><span className='b-isotope-grid__categorie'>Branding</span></span>
                  </span>
                  </a>
                </li>
                <li className='b-isotope-grid__item grid-item photography video illustration'>
                  <a className='b-isotope-grid__inner' href='portfolio-details.html'><img src={portfolioDetails5} alt='foto' /><span className='b-isotope-grid__wrap-info hvr-shutter-in-vertical'><span className='b-isotope-grid__info'><span className='b-isotope-grid__title'>emma card</span><span className='b-isotope-grid__categorie'>Branding</span></span>
                  </span>
                  </a>
                </li>
                <li className='b-isotope-grid__item grid-item video'>
                  <a className='b-isotope-grid__inner' href='portfolio-details.html'><img src={portfolioDetails6} alt='foto' /><span className='b-isotope-grid__wrap-info hvr-shutter-in-vertical'><span className='b-isotope-grid__info'><span className='b-isotope-grid__title'>emma card</span><span className='b-isotope-grid__categorie'>Branding</span></span>
                  </span>
                  </a>
                </li>
              </ul>
            </section>
          )
          : null
        }
        {/* end .b-isotope */}
        <section className='b-info'>
          <div className='b-info__inner border-l_prim border-r_prim'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-8'>
                  <div className='b-info__title'>{content.en.helpsBanner}</div>
                </div>
                <div className='col-md-4'>
                  <div className='b-info__link'>
                    <AnchorLink
                      className='b-info__btn text-primary_h'
                      href='#contact'
                    >
                  let’s create amazing<span className='b-info__btn-emphasis'>ideas</span>
                    </AnchorLink>
                    <div className='b-info__link-bg'><i className='icon pe-7s-pen' /></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* end .b-info */}
        <div className='section-default' id='services'>
          <div className='row section-row-reverse'>
            <div className='col-md-6'><img className='img-responsive' src={imgResponsive2} alt='foto' /></div>
            <div className='col-md-6'>
              <section className='section-services'>
                <h2 className='ui-title-block' id='revealfx3'>The Services !</h2>
                <div className='ui-subtitle-block'>What
                  <br />W<span className='shuffle'>e offer</span></div>
                <div className='owl-carousel owl-theme enable-owl-carousel' data-pagination='true' data-navigation='false' data-single-item='true' data-auto-play={7000} data-transition-style='fade' data-main-text-animation='true' data-after-init-delay={3000} data-after-move-delay={1000} data-stop-on-hover='true'>
                  <div className='b-advantages-group'>
                    <ServicesSection
                      title={content.en.services.ecommerce.title}
                      description={'We take into consideration each detail when analyzing your data to create new ideas and open opportunities for your business. We are highly-qualified in ecommerce digital campaign management, email marketing strategy, reporting, organic SEO, reporting SEO'}
                      icon={<img className='offer-icon' src={EcommerceStrategyIcon} alt={content.en.services.ecommerce.title} />}
                    />
                    <ServicesSection
                      title={content.en.services.backend.title}
                      description={'Our backend developers are the team of certified professionals who have built 20+ sites, 30+ customizations and a number of integrations. Whether you need a proven solution integrator to launch your site- Raccoon LLC is a trusted partner to lead backend development and data migration services.'}
                      icon={<img className='offer-icon' src={BackIcon} alt={content.en.services.backend.title} />}
                    />
                  </div>
                  <div className='b-advantages-group'>
                    <ServicesSection
                      title={content.en.services.frontend.title}
                      description={'We are creating interfaces that people love. We optimize  web pages for maximum speed and scalability. Our engineers maintain brand consistency throughout the design. Loads fast, looks great, works without any tiny hiccup. Each product is made with great care to make it look awesome on iMac screens or mobile devices'}
                      icon={<img className='offer-icon' src={FrontIcon} alt={content.en.services.frontend.title} />}
                    />
                    <ServicesSection
                      title={content.en.services.analytics.title}
                      description={content.en.services.analytics.description}
                      icon={<img className='offer-icon' src={AnalyticsIcon} alt={content.en.services.analytics.title} />}
                    />
                  </div>
                  <div className='b-advantages-group'>
                    <ServicesSection
                      title={content.en.services.solution.title}
                      description={content.en.services.solution.description}
                      icon={<img className='offer-icon' src={ConsultingIcon} alt={content.en.services.solution.title} />}
                    />
                    <ServicesSection
                      title={'Industry Proficiency '}
                      description={(
                        <div>
                      Consultants with deep knowledge of and experience in: <br />
                      - Healthcare<br />
                      - Retail &amp; Distribution<br />
                      - Media &amp; Entertainment<br />
                      - Finance &amp; Insurance
                        </div>
                      )}
                      icon={<img className='offer-icon' src={StarIcon} alt={'Industry Proficiency '} />}
                    />
                  </div>

                </div>
              </section>
            </div>
          </div>
        </div>
        <div className='section-blockquote'>
          <div className='row section-blockquote__grid'>
            <div className='col-md-8'>
              <div className='owl-carousel owl-theme enable-owl-carousel' data-pagination='true' data-navigation='false' data-single-item='true' data-auto-play={7000} data-transition-style='fade' data-main-text-animation='true' data-after-init-delay={3000} data-after-move-delay={1000} data-stop-on-hover='true'>
                <div className='b-blockquote b-blockquote-2'>
                  <blockquote>
                    <p>It`s a great pleasure to work with your company, I have seen highly- skilled expertise in Salesforce Commerce Cloud projects . Proactive, ambitious, committed and broad-minded team. Excellent and result driven. All developers towards maximal customer satisfaction.</p>
                    <cite className='b-blockquote__cite' title='Blockquote Title'><span className='b-blockquote__author'>Sarah Nurnberg</span><span className='b-blockquote__category'>Designer, Digital Agency.</span></cite>
                  </blockquote>
                </div>
                {/* end .b-blockquote */}
                <div className='b-blockquote b-blockquote-2'>
                  <blockquote>
                    <p>I was working with the company during last year and still impressed by the passion to web development and common goal achievements. The team members showed a really good performance while engineering software, continuously improving their professional level in tech and soft sides. Their incredible commitment is very noticeable.</p>
                    <cite className='b-blockquote__cite' title='Blockquote Title'><span className='b-blockquote__author'>Nicolas Francesco</span><span className='b-blockquote__category'>Senior Developer, Vans.</span></cite>
                  </blockquote>
                </div>
                {/* end .b-blockquote */}
                <div className='b-blockquote b-blockquote-2'>
                  <blockquote>
                    <p>I had the opportunity to work with Raccoon LLC in the project of SFCC implementation. I`ve met here great developers with a superb vision of the project. Developers find an incredible solving of any issue and he management is  able to find the best solutions. Overall, it was a pleasure to work with the Company.</p>
                    <cite className='b-blockquote__cite' title='Blockquote Title'><span className='b-blockquote__author'>Richard Robson</span><span className='b-blockquote__category'>Head of Consulting Group.</span></cite>
                  </blockquote>
                </div>
                {/* end .b-blockquote */}
              </div>
            </div>
            <div className='col-md-4'>
              <div className='section-blockquote__quote'><span className='figure' />
                <div className='section-blockquote__quote-inner bg-primary'><i className='icon text-primary fa fa-quote-right' /></div>
              </div>
            </div>
          </div>
        </div>
        <section className='section-default' id='why-us'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-xs-12'>
                <h2 className='ui-title-block'>What We Charge !</h2>
                <div className='ui-subtitle-block'>
                  Why Us?
                </div>
              </div>
            </div>
            <div className='row why-us-list'>
              <div className='col-sm-4 why-us-list__item'>
                <section className='b-pricing'>
                  <div className='clearfix'>
                    <h3 className='b-pricing__title'>
                      <img className='whyus-icon' src={solversIcon} alt={'Result Oriented'} />
                      Result Oriented
                    </h3>
                    {/* <div className='b-pricing-price'><span className='b-pricing-price__number'>$24</span><span className='b-pricing-price__title'>/ month</span></div> */}
                  </div>
                  <div className='text-center'>
                    <div className='b-pricing__description list-unstyled'>
                    “We are aimed on the results and don’t worry about the how.” Our target is satisfied customers.
                    We  know «HOW» the results can be reached by analyzing data to achieve those things that matter most to our customers and apply to them our extensive experience.

                      </div>
                      {/* <a className='b-pricing__btn btn btn-type-1' href='home.html'><span className='btn-type-1__inner'>purchase</span><i className='icon fa fa-long-arrow-right' /></a> */}
                    </div>
                  </section>
                  {/* end .b-pricing */}
                </div>
                <div className='col-sm-4 why-us-list__item'>
                  <section className='b-pricing'>
                    <div className='clearfix'>
                      <h3 className='b-pricing__title'>
                        <img className='whyus-icon' src={ecommerceInsideIcon} alt={'WE KNOW ECOMMERCE INSIDE OUT'} />
                        WE KNOW ECOMMERCE INSIDE OUT
                      </h3>
                      {/* <div className='b-pricing-price'><span className='b-pricing-price__number'>$36</span><span className='b-pricing-price__title'>/ month</span></div> */}
                    </div>
                    <div className='text-center'>
                      <div className='b-pricing__description list-unstyled'>
                    We are experienced, certified digital commerce specialists who use ecommerce products on a daily basis. We can develop personalized ecommerce strategies for you that make your business sense.
                      </div>
                      {/* <a className='b-pricing__btn btn btn-type-1' href='home.html'><span className='btn-type-1__inner'>purchase</span><i className='icon fa fa-long-arrow-right' /></a> */}
                    </div>
                  </section>
                  {/* end .b-pricing */}
                </div>
                <div className='col-sm-4 why-us-list__item'>
                  <section className='b-pricing'>
                    <div className='clearfix'>
                      <h3 className='b-pricing__title'>
                        <img className='whyus-icon' src={resultIcon} alt={'PROBLEM SOLVERS'} />
                        PROBLEM SOLVERS
                      </h3>
                      {/* <div className='b-pricing-price'><span className='b-pricing-price__number'>$48</span><span className='b-pricing-price__title'>/ month</span></div> */}
                    </div>
                    <div className='text-center'>
                      <div className='b-pricing__description list-unstyled'>
                  Whether you are launching a site or integrating a feature, you need to know how you are going to identify the problem, find out a solution and implement it. You can improve your problem-solving skills by… or
                  Just leave it to us
                      </div>
                      {/* <a className='b-pricing__btn btn btn-type-1' href='home.html'><span className='btn-type-1__inner'>purchase</span><i className='icon fa fa-long-arrow-right' /></a> */}
                    </div>
                  </section>
                  {/* end .b-pricing */}
                </div>
              </div>
            </div>
          </section>
          {doShowBlog && (
            <section className='section-news section-default' id='blog'>
              <div className='area-bg__inner'>
                <div className='container'>
                  <div className='row'>
                    <div className='col-xs-12'>
                      <h2 className='ui-title-block' id='revealfx4'>Latest News !</h2>
                      <div className='ui-subtitle-block'>What
                        <br /> W<span className='shuffle'>e think</span></div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-lg-6 col-md-4'>
                      <section className='b-post-1 b-post clearfix'>
                        <div className='entry-media'>
                          <a className='js-zoom-images' href={imageZoom}>
                            <img className='img-responsive' src={imageZoom} alt='Foto' /></a>
                        </div>
                        <div className='entry-main'>
                          <div className='entry-meta'><span className='entry-meta__item'>By:<a className='entry-meta__link' href='blog-main.html'>&nbsp;Lawrance</a></span><span className='entry-meta__item'><a className='entry-meta__link' href='blog-main.html'>
                            <time dateTime='2012-10-27'>10 January 2017</time></a></span></div>
                          <div className='entry-header'>
                            <h2 className='entry-title'><a href='blog-post.html'>Aon eiusmod tempor incididunt</a></h2>
                          </div>
                          <div className='entry-content'>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit sed tempore incididunt labore dolore magna aliqua enim veniam ...</p>
                          </div>
                          <div className='entry-footer'><a className='btn btn-default' href='blog-post.html'>read more<i className='icon fa fa-long-arrow-right' /></a></div>
                        </div>
                      </section>
                    </div>
                    <div className='col-lg-3 col-md-4'>
                      <section className='b-post-sm b-post clearfix'>
                        <div className='entry-main'>
                          <div className='entry-meta'><span className='entry-meta__item'>By:<a className='entry-meta__link' href='blog-main.html'>&nbsp;Lawrance</a></span><span className='entry-meta__item'><a className='entry-meta__link' href='blog-main.html'>
                            <time dateTime='2012-10-27'>10 January 2017</time></a></span></div>
                          <div className='entry-header'>
                            <h2 className='entry-title'><a href='blog-post.html'>Eiusmod tempor incididunt anim elit manga</a></h2>
                          </div>
                        </div>
                        <div className='entry-media'>
                          <a className='js-zoom-images' href={imageZoom1}>
                            <img className='img-responsive' src={imageZoom1} alt='Foto' /></a>
                        </div>
                      </section>
                      {/* end .post */}
                    </div>
                    <div className='col-lg-3 col-md-4'>
                      <section className='b-post-sm b-post clearfix'>
                        <div className='entry-main'>
                          <div className='entry-meta'><span className='entry-meta__item'>By:<a className='entry-meta__link' href='blog-main.html'>&nbsp;Lawrance</a></span><span className='entry-meta__item'><a className='entry-meta__link' href='blog-main.html'>
                            <time dateTime='2012-10-27'>10 January 2017</time></a></span></div>
                          <div className='entry-header'>
                            <h2 className='entry-title'><a href='blog-post.html'>Labore dolore magna aliqua minim veniam quis</a></h2>
                          </div>
                        </div>
                        <div className='entry-media'>
                          <a className='js-zoom-images' href={imageZoom2}>
                            <img className='img-responsive' src={imageZoom2} alt='Foto' /></a>
                        </div>
                      </section>
                      {/* end .post */}
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-xs-12'><a className='section-news__link btn btn-type-1' href='portfolio.html'><span className='btn-type-1__inner'>visit blog</span><i className='icon fa fa-long-arrow-right' /></a></div>
                  </div>
                </div>
              </div>
            </section>
          )}
          {/* end .section-news */}
          {doShowPartners && (
            <div className='section-brands bg-dark'>
              <div className='row'>
                <div className='col-xs-12'>
                  <div className='b-brands-2 owl-carousel owl-theme enable-owl-carousel' data-min480={1} data-min768={3} data-min992={5} data-min1200={5} data-pagination='false' data-navigation='false' data-auto-play={4000} data-stop-on-hover='true'>
                    <div className='b-brands-2__item'><img className='img-responsive center-block' src={carousel1} alt='foto' /></div>
                    <div className='b-brands-2__item'><img className='img-responsive center-block' src={carousel2} alt='foto' /></div>
                    <div className='b-brands-2__item'><img className='img-responsive center-block' src={carousel3} alt='foto' /></div>
                    <div className='b-brands-2__item'><img className='img-responsive center-block' src={carousel4} alt='foto' /></div>
                    <div className='b-brands-2__item'><img className='img-responsive center-block' src={carousel5} alt='foto' /></div>
                    <div className='b-brands-2__item'><img className='img-responsive center-block' src={carousel1} alt='foto' /></div>
                    <div className='b-brands-2__item'><img className='img-responsive center-block' src={carousel2} alt='foto' /></div>
                    <div className='b-brands-2__item'><img className='img-responsive center-block' src={carousel3} alt='foto' /></div>
                    <div className='b-brands-2__item'><img className='img-responsive center-block' src={carousel4} alt='foto' /></div>
                    <div className='b-brands-2__item'><img className='img-responsive center-block' src={carousel5} alt='foto' /></div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className='section-form-1' id='contact'>
            <div className='block-table form-row-reverse'>
              <div className='block-table__cell col-sm-6'>
                <div className='section-form-1__inner'>
                  <h2 className='ui-title-block'>Get In Touch !</h2>
                  <div className='ui-subtitle-block'>send a
                    <br />message</div>
                  <ContactForm />
                </div>
              </div>
              <div className='block-table__cell col-sm-6'>
                <div className='block-table__inner'><img className='section-form-1__img' src={sectionFormImg} alt='foto' /></div>
              </div>
            </div>
          </div>
        </div>
        {/* end .section-social-net */}
      </main>
      <CookieConsent
        location='bottom'
        buttonText='I agree'
        cookieName='myAwesomeCookieName2'
        style={{
          background: '#222222c2',
          fontFamily: 'sans-serif'
        }}
        buttonStyle={{ color: '#000', fontSize: '13px', backgroundColor: '#dde04e' }}
        expires={150}
      >
      This website uses cookies to enhance the user experience. {' '}
        <a href='/cookie-policy' target='_blank'>Learn more</a>
      </CookieConsent>
    </>
  )
}

export default Home
