import React from 'react'

const ServicesSection = ({ title, description, icon }) => {
  return (
    <section className='b-advantages b-advantages-1 b-advantages-1_mod-a'>
      <div className='services-container'>
        <h3 className='b-advantages__title ui-title-inner services-title'>
          {title}
        </h3>
        {icon}
      </div>
      <div className='b-advantages__info'>{description}</div>
    </section>
  )
}

export default ServicesSection
