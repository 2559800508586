import React from 'react'
import PageMetaTags from '../components/PageMetaTags'

const CookiePolicy = () => (
  <main className='l-main-content' id='home'>
    <PageMetaTags
      title="Raccoon LLC - Cookies"
      description="Cookie Policy of Raccoon LLC. We may collect information about your computer, including where available your IP address, operating system and browser type."
      ogTitle="Raccoon LLC - Cookies"
      ogDescription="Cookie Policy of Raccoon LLC. We may collect information about your computer, including where available your IP address, operating system and browser type."
      ogType="article"
      ogUrl="https://raccoon.pet/cookie-policy"
      />
    <section className='section-first section-first_type-2'>
      <div className='container'>
        <h5>Cookies</h5>
        <p>We may collect information about your computer, including where available your IP address, operating system and browser type, for system administration and to report aggregate information to our advertisers. This is statistical data about our users’ browsing actions and patterns, and does not identify any individual. For the same reason, we may obtain information about your general internet usage by using a cookie file which is stored on the hard drive of your computer. Cookies contain information that is transferred to your computer’s hard drive. They help us to improve the Site and to deliver a better and more personalised service. They enable us:</p>
        <br/>
        <ul>
          <li>To estimate our audience size and usage pattern.</li>
          <li>To store information about your preferences, and so allow us to customise the Site according to your individual interests.</li>
          <li>To speed up your searches.</li>
          <li>To recognise you when you return to the Site.</li>
          <br/>
        </ul>
        <p>You can set your browser not to accept cookies and the above website tells you how to do that. However, in a few cases some of the features on our Site may not function as a result. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you log on to the Site.</p>
      </div>
    </section>
  </main>
)

export default CookiePolicy
