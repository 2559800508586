import React from 'react'

const MenuToggle = () => (
  <button type='button' className='fullmenu-toggle js-toggle-menu-button'>
    <span className="icon-bar line-1"></span>
    <span className="icon-bar line-2"></span>
    <span className="icon-bar line-3"></span>
  </button>
)

export default MenuToggle
