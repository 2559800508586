import React from 'react'
import logo from '../assets/media/images/header/logo2.3.svg'
import logoDark from '../assets/media/images/header/logo2.1.svg'

const Logo = ({
  altText = 'Raccoon LLC'
}) => (
  <div className='yamm main-menu nav navbar-nav logo'>
      <a className='navbar-brand scroll' href='./'>
        <img className='normal-logo' src={logo} alt={altText} />
        <img style={{ width: '120px' }} className='scroll-logo hidden-xs' src={logoDark} alt={altText} />
      </a>
  </div>
)

export default Logo
