import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'

const LinkWithAnchor = ({
  to,
  text,
  isHomepage = false,
  className = ''
}) => (
  <>
    {isHomepage
      ? (
        <AnchorLink
          className={className}
          href={`#${to}`}
        >
          {text}
        </AnchorLink>
      )
      : (
        <a
          className={className}
          href={`/#${to}`}
        >
          {text}
        </a>)
    }
  </>
)

export default LinkWithAnchor
