import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import InfoModal from './InfoModal'


const contactUrl = `${process.env.REACT_APP_BACKEND_HOST}/api/v1/saveEmail`

const NewsletterForm = () => {
  const { register, handleSubmit, errors } = useForm()
  const formRef = useRef(null)
  const [state, setState] = useState({
    showModal: false,
    modalMessage: '',
    isSuccess: false
  });

  const removeInfoModal = () => {
    setState({
      showModal: false,
      modalMessage: '',
      isSuccess: false
    })
  }

  const onSubmit = async data => {
    removeInfoModal()
    try {
      const res = await axios.post(contactUrl, data)

      if (res.data.success) {
        if (formRef.current) {
          formRef.current.reset()
        }

        setState({
          showModal: true,
          modalMessage: 'Thank you for subscription!',
          isSuccess: true
        })
        // alert(${data.name}, thank you for your message! We'll contact you soon!)
      } else {
        setState({
          showModal: true,
          modalMessage: 'An error occured, try again later!',
          isSuccess: false
        })
        // alert('An error occured, try again later!')
      }
    } catch (e) {
        setState({
            showModal: true,
            modalMessage: 'An error occured, try again later!',
            isSuccess: false
          })
      // alert('An error occured, try again later!')
    }
    setTimeout(removeInfoModal, 5000)
  }

  return (
    <>
      <form
        className='footer-form'
        onSubmit={handleSubmit(onSubmit)}
        ref={formRef}
      >
        <input
              name='email'
              className={`footer-form__input ${errors.email ? 'required-field' : ''}`}
              type='email'
              placeholder='Email address'
              maxLength='30'
              regexp="^[\w.%+-]+@[\w.-]+\.[\w]{2,6}$"
              ref={register({ required: true, pattern: /^[\w.%+-]+@[\w.-]+\.[\w]{2,6}$/ })}
        />
        <button className='footer-form__btn border-b_prim text-primary_h'><i className='icon fa fa-long-arrow-right' /></button>
      </form>
      {errors.email && errors.email.type === "required" && <span className="required-text">This field is required</span>}
      {errors.email && errors.email.type === "pattern" && <span className="required-text">This field is not valid</span>}

      <InfoModal
        isSuccess={state.isSuccess}
        show={state.showModal}
        modalMessage={state.modalMessage}
      />
    </>
  )
}

export default NewsletterForm