import React from 'react'
import PageMetaTags from '../components/PageMetaTags'

const CookiePolicy = () => (
  <main className='l-main-content' id='home'>
    <PageMetaTags
      title="Raccoon LLC - Privacy Policy"
      description="Privacy Policy of Raccoon LLC. About us, What information do we collect about you?, Cookies, Children, Your rights, Questions and Complaint."
      ogTitle="Raccoon LLC - Privacy Policy"
      ogDescription="Privacy Policy of Raccoon LLC. About us, What information do we collect about you?, Cookies, Children, Your rights, Questions and Complaint."
      ogType="article"
      ogUrl="https://raccoon.pet/privacy-policy"
      />
    <section className='section-first section-first_type-2'>
      <div className='container'>
        <h4>
          Privacy Policy
        </h4>
        <ol>
          <br/>
          <li>
            <h5>About us</h5>
          (We) Raccoon LLC are committed to protecting and respecting your privacy and we are considered a data controller for purposes of the European General Data Protection Regulation.
          This policy sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us. Please read the following policy carefully to understand our views and practices regarding your personal data and how we will treat it.
          </li>
          <br/>
          <li>
            <h5>What information do we collect about you?</h5>
            We may collect and process the following information about you:

            Information that you provide by filling in forms on our site raccoon.pet (the ‘Site’) (including your personal details, contact details and other information you choose to provide to us when contacting us).
            If you contact us, we may keep a record of that correspondence.
            Website usage information is also collected using cookies (see below under ‘Cookies’).
          </li>
          <br/>
          <li>
            <h5>How will we use the information about you?</h5>

            <p>We use the information we hold about you in the following ways:</p>
            <ul>
              <li>To notify you of news, updates or announcements related to our business which we believe may be of interest to you and for third party marketing purposes (if you consent)</li>
              <li>To help us improve the structure, layout, and usability of the Site.</li>
              <li>To help personalise your repeat visits to the Site.</li>
              <li>To answer any questions you may raise or respond to any comments you may send to us.</li>
              <li>To notify you about changes to our service or the functionality of the Site.</li>
              <li>To carry out market analysis and research.</li>
              <li>To ensure that we comply with our statutory and regulatory obligations.</li>
              <li>To allow you to use the Site and all its features and services.</li>
            </ul>
          </li>
          <br/>
          <li>
            <h5>Disclosure of your information</h5>
            <p>As we continue to develop our business, we might sell or buy assets. In such transactions, the information we have collected about you will generally be one of the transferred business assets. Also in the unlikely event that we or our assets are purchased, your information will also be one of the transferred assets. We may share information about you with:</p>
            <ul>
              <li>Other companies in the same corporate group as us.</li>
              <li>A buyer or prospective buyer of our business.</li>
              <li>Our professional advisers, such as our lawyers, where it is necessary for them to advise us.</li>
              <li>Other persons if we are under a duty to disclose or share your information in order to comply with any legal obligation.</li>
            </ul>
          </li>
          <p>We may also share information about you with third parties that we engage to help us in certain areas of our operations, however, we ensure that all such third parties “processing” your data are subscribed to standard industry privacy protection terms and conditions.</p>
          <br/>
          <li>
            <h5>Sharing data for advertising purposes</h5>
            <ol type='a'>
              <br/>
              <li>
                <h5>Anonymous Data</h5>
                <p>So we can better understand our audience and improve our service, such anonymous information is provided to third parties for their business purposes, including the creation of reports, market research, and trend analysis. This information does not contain information from which users can be identified and we will not provide additional information to such third parties that enable such third parties to identify you. Notwithstanding any other provision, we may also engage a third-party partner for the purpose of identifying users and delivering to them interest-based content and advertisements. We may share information about you with our partners such as your name, e-mail, or other identifier and may do so in non-human readable format to ensure the security of your information.</p>
              </li>
              <br/>
              <li>
                <h5>Sometimes it&apos;s personal data</h5>
                <p>Our partners also may collect information directly from your device, such as your IP address, device ID (which is in some countries considered personal data) or similar information regarding the location of your mobile device; may combine our personal and non-personal information about you with information from other sources; and may place or recognize a unique cookie on your browser. If we are sharing ANY data that is considered to be personal data by the appropriate laws of the country in which you live, we will always ask your consent before we use your data in this way (and you can ask us to stop at any time).</p>
              </li>
            </ol>
          </li>
          <br/>
          <li>
            <h5>Cookies</h5>
            <p>We may collect information about your computer, including where available your IP address, operating system and browser type, for system administration and to report aggregate information to our advertisers. This is statistical data about our users’ browsing actions and patterns, and does not identify any individual. For the same reason, we may obtain information about your general internet usage by using a cookie file which is stored on the hard drive of your computer. Cookies contain information that is transferred to your computer’s hard drive. They help us to improve the Site and to deliver a better and more personalised service. They enable us:</p>
            <br/>
            <ul>
              <li>To estimate our audience size and usage pattern.</li>
              <li>To store information about your preferences, and so allow us to customise the Site according to your individual interests.</li>
              <li>To speed up your searches.</li>
              <li>To recognise you when you return to the Site.</li>
              <br/>
              <p>You can set your browser not to accept cookies and the above website tells you how to do that. However, in a few cases some of the features on our Site may not function as a result. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you log on to the Site.</p>
            </ul>
          </li>
          <br/>
          <li>
            <h5>Where we store your personal data</h5>
            <p>The personal data that we collect from you may be transferred to, and stored at, a location outside the European Economic Area (‘EEA’). It may also be processed by staff located outside the EEA who work for us or for one of our suppliers. By submitting your personal data to us through the Site, you agree to this transfer, storing or processing. We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this privacy policy.</p>
            <br/>
            <p>If any part of our Site requires login access, you are responsible for keeping your login credentials secure.</p>
            <br/>
            <p>Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to the Site; any transmission is at your own risk. Once we have received your information, we will use procedures and security features to try to prevent unauthorised access.</p>
          </li>
          <br/>
          <li>
            <h5>How long we store your data for</h5>
            <p>We store your data for so long as you are using our Site, plus a period of 12 months to allow us to respond to any regulatory or legal requests and enquiries including from law enforcement.</p>
          </li>
          <br/>
          <li>
            <h5>Children</h5>
            <p>The Site is not intended for use by children under the age of 18. We do not knowingly collect (or knowingly allow any third party to collect) personal information from persons under the age of 18. If we become aware that personal information has been collected from a person under the age of 18, we will delete this information and terminate the person’s account as quickly as possible. If you believe that we may have personal information from or about a child under the age of 18, please contact us using the contact information below.</p>
          </li>
          <br/>
          <li>
            <h5>Other Websites</h5>
            <p>The Site may, from time to time, contain links to and from the websites of our partner networks, advertisers and affiliates. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies. Please check these policies before you submit any personal data to these websites.</p>
          </li>
          <br/>
          <li>
            <h5>Your rights</h5>
            <p>To request a copy of any personal data we hold: You have the right to request a copy of the personal information that we hold about you. If you would like a copy of some or all of your personal information, please contact us.</p>
            <br/>
            <p>To update and amend any personal data we hold: We want to make sure that your personal information is accurate and up to date. You can ask us to correct or remove information you think is inaccurate at any time by contacting us.</p>
            <br/>
            <p>To be “forgotten”: You have the right to ask us to delete all of the personal data we hold about you. Doing so may result in you being unable to use the Site if the data is required for your use of the Site. We are permitted to retain some or all of the data for a period of 12 months following your request in order that we may respond to any legal or regulatory investigation, if any so arises, including inquiries from law enforcement.</p>
            <br/>
            <p>To amend your consents: You may change the consents you grant to us regarding how we are permitted to use your personal data and you may do so at any time by contacting us.</p>
          </li>
          <br/>
          <li>
            <h5>Changes to our privacy policy</h5>
            <p>We keep our privacy policy under regular review and will post any changes to it on this page. We encourage you to regularly review our privacy policy to make sure you are always aware of what information we collect, how we use it and under what circumstances if any, we may share it with other parties.</p>
          </li>
          <br/>
          <li>
            <h5>Questions and Complaints</h5>
            <p>If you have any questions about this privacy policy then please contact us via  <a href='mailto:mail@raccoon.pet' className='privacy-link'>mail@raccoon.pet</a> </p>
            <br/>
            <p>We take data protection very seriously. If you have concerns or complaints please address these to us</p>
          </li>
        </ol>
      </div>
    </section>
  </main>
)

export default CookiePolicy
