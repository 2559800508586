import React from 'react'
import logo from '../assets/media/images/footer/logo-white.svg?v=0.1'
import raccoon from '../assets/media/images/footer/raccoon-llc.svg'

import LinkWithAnchor from './LinkWithAnchor'
import BackToTop from './BackToTop'
import NewsletterForm from './NewsletterForm'

const Footer = ({
  isHomepage,
  doShowProjects,
  doShowBlog
}) => (
  <>
  <footer className='footer'>
      <div className='footer__main container-fluid'>
        <div className='row'>
          <div className='col-md-4 col-sm-4'>
            <div className='footer-section'>
              <a className='footer__logo' href={`./`}>
                <img className='footer__logo-image img-responsive' src={logo} alt='Raccoon LLC' />
                <img className='footer__logo-title img-responsive' src={raccoon} alt='Raccoon LLC' />
              </a>
              <div className='footer__info'>
                Headquarters: 30 N Gould St, STE R, Sheridan, WY 82801, USA
                <br/> Delivery office: 5A Nansena Str, Vinnytsia, 21001, Ukraine
                <hr /> <a style={{ color: '#ccc' }} href='mailto:mail@raccoon.pet'>mail@raccoon.pet</a>
                <br /><a href="tel:+1707500-05-99" className='footer__info-tel'>+1 707 500-05-99</a>
              </div>
            </div>
          </div>
          <div className='col-md-4 col-sm-4'>
            <section className='footer-section footer-section_list'>
              <h3 className='footer-section__title'>company</h3>
              <ul className='footer-list list-unstyled'>
                <li className='footer-list__item'>
                  <LinkWithAnchor
                    to='home'
                    isHomepage={isHomepage}
                    text='Home'
                    className='footer-list__link'
                  />
                </li>
                <li className='footer-list__item'>
                  <LinkWithAnchor
                    to='capabilities'
                    isHomepage={isHomepage}
                    text='Capabilities'
                    className='footer-list__link'
                  />
                </li>
                {doShowProjects && (
                  <li className='footer-list__item'>
                    <LinkWithAnchor
                      to='works'
                      isHomepage={isHomepage}
                      text='Works'
                      className='footer-list__link'
                    />
                  </li>
                )}
                <li className='footer-list__item'>
                  <LinkWithAnchor
                    to='services'
                    isHomepage={isHomepage}
                    text='Services'
                    className='footer-list__link'
                  />
                </li>
                <li className='footer-list__item'>
                  <LinkWithAnchor
                    to='why-us'
                    isHomepage={isHomepage}
                    text='Why Us'
                    className='footer-list__link'
                  />
                </li>
                {doShowBlog && (
                  <li className='footer-list__item'>
                    <LinkWithAnchor
                      to='blog'
                      isHomepage={isHomepage}
                      text='Blog'
                      className='footer-list__link'
                    />
                  </li>
                )}
                <li className='footer-list__item'>
                  <LinkWithAnchor
                    to='contact'
                    isHomepage={isHomepage}
                    text='Contact'
                    className='footer-list__link'
                  />
                </li>
              </ul>
            </section>
          </div>
          <div className='col-md-4 col-sm-4'>
            <div className='footer-section footer-section_form'>
              <h3 className='footer-section__title'>newsletter</h3>
              <div className='footer-form__info'>Sign up to get the latest news, insights, and trends straight to your inbox</div>
              <NewsletterForm />
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-xs-12'>
            <div className='copyright'>
              Raccoon LLC (c) {new Date().getFullYear()}. All Rights Reserved <br/>
              <a className='footer-list__link' href='/privacy-policy' target='blank'>Privacy Policy</a>
            </div>
          </div>
        </div>
    </div>
  </footer>

  <BackToTop />
  </>
)

export default Footer
