import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import InfoModal from './InfoModal'

const contactUrl = `${process.env.REACT_APP_BACKEND_HOST}/api/v1/contact`

const ContactForm = () => {
  const { register, handleSubmit, errors } = useForm()
  const formRef = useRef(null)
  const [state, setState] = useState({
    showModal: false,
    dataName: '',
    modalMessage: '',
    isSuccess: false
  });

  const removeInfoModal = () => {
    setState({
      showModal: false,
      dataName: '',
      modalMessage: '',
      isSuccess: false
    })
  }

  const onSubmit = async data => {
    removeInfoModal()

    try {
      const res = await axios.post(contactUrl, data)

      if (res.data.success) {
        if (formRef.current) {
          formRef.current.reset()
        }

        setState({
          showModal: true,
          dataName: data.name,
          modalMessage: 'thank you for your message! We\'ll contact you soon!',
          isSuccess: true
        })
        // alert(`${data.name}, thank you for your message! We'll contact you soon!`)
      } else {
        setState({
          showModal: true,
          dataName: '',
          modalMessage: 'An error occured, try again later!',
          isSuccess: false
        })
        // alert('An error occured, try again later!')
      }
    } catch (e) {
      setState({
        showModal: true,
        dataName: '',
        modalMessage: 'An error occured, try again later!',
        isSuccess: false
      })
      // alert('An error occured, try again later!')
    }
    setTimeout(removeInfoModal, 5000)
  }

  return (
    <>
      <form
        className='ui-form ui-form-1'
        onSubmit={handleSubmit(onSubmit)}
        ref={formRef}
      >
        <div className='row'>
          <div className='col-md-6'>
            <input
              name='name'
              className={`form-control ${errors.name ? 'required-field' : ''}`}
              type='text'
              placeholder='Full Name'
              maxLength='30'
              ref={register({ required: true })}
            />
            {errors.name && errors.name.type === "required" && <span className="required-text">This field is required</span>}
          </div>
          <div className='col-md-6'>
            <input
              name='email'
              className={`form-control ${errors.email ? 'required-field' : ''}`}
              type='email'
              placeholder='Work Email'
              maxLength='40'
              ref={register({ required: true, pattern: /^[\w.%+-]+@[\w.-]+\.[\w]{2,6}$/ })}
            />
            {errors.email && errors.email.type === "required" && <span className="required-text">This field is required</span>}
            {errors.email && errors.email.type === "pattern" && <span className="required-text">This field is not valid</span>}
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <input
              name='phone'
              className={`form-control ${errors.phone ? 'required-field' : ''}`}
              type='text'
              placeholder='Phone Number'
              maxLength='20'
              ref={register({pattern: /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/ })}
            />
            {errors.phone && errors.phone.type === "pattern" && <span className="required-text">This field is not valid</span>}
          </div>
          <div className='col-md-6'>
            <input
              name='website'
              className='form-control'
              type='text'
              placeholder='Company Name'
              maxLength='20'
              ref={register}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <textarea
              style={{ resize: 'none' }}
              name='message'
              className='form-control'
              rows={6}
              placeholder='Message'
              maxLength='300'
              defaultValue={''}
              ref={register}
            />
            <button
              className='btn btn-type-1'
              type='submit'
            >
              <span className='btn-type-1__inner'>send a message</span>
              {/* <i className='icon fa fa-long-arrow-right' /> */}
            </button>
          </div>
        </div>
      </form>

      <InfoModal
        isSuccess={state.isSuccess}
        show={state.showModal}
        userName={state.dataName}
        modalMessage={state.modalMessage}
      />
    </>
  )
}

export default ContactForm
